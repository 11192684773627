import React from 'react'

const Header = () => (
  <header className="text-center py-4 font-philosopher">
    <div className="w-auto inline-block select-none">
      <h2 className="text-right text-3xl">proeflokaal</h2>
      <h1 className="text-7xl text-secondary font-bold">'t Hert</h1>
    </div>
  </header>
)

export default Header

import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <main className="text-center p-4">
      <h3 className="text-5xl font-philosopher font-bold pb-4">Oeps!</h3>
      <h4 className="text-2xl py-4">Deze pagina bestaat niet...</h4>
      <Link to="/">
        <button className="bg-secondary p-4 text-center border-tertiary border-2 rounded-full mt-4">
          Menukaart
        </button>
      </Link>
    </main>
  )
}

export default NotFound

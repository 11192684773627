import React, { useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import Loader from './Loader'

const Categories = ({ categories }) => {
  if (categories === null) return <Loader />

  return (
    <main>
      {categories &&
        categories.map(category => (
          <Category
            key={category._id}
            name={category.name}
            alwaysActive={category.alwaysActive}
          >
            {category.info.map(info => (
              <Info key={info.name} content={info.content} />
            ))}
            {category.drinks.map(drink => (
              <Drink
                key={drink.slug.current}
                name={drink.name}
                percentage={drink.percentage}
                inStock={drink.inStock}
              />
            ))}
          </Category>
        ))}
    </main>
  )
}

export default Categories

const Category = ({ name, alwaysActive = false, children }) => {
  const [active, setActive] = useState(alwaysActive)

  return (
    <div
      className={
        'w-full text-xl  select-none ' + (!alwaysActive && 'cursor-pointer')
      }
    >
      <div
        className={'p-3 flex ' + (active ? 'bg-secondary' : 'bg-primary')}
        onClick={() => !alwaysActive && setActive(!active)}
      >
        {name}
        <span className="ml-auto">
          {alwaysActive ||
            (active ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-secondary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            ))}
        </span>
      </div>
      <ul className={'bg-tertiary ' + (active || 'hidden')}>{children}</ul>
    </div>
  )
}

const Drink = ({ name, percentage = null, inStock = true }) => (
  <li
    className={
      'flex list-none w-full p-3 cursor-default select-none ' +
      (!inStock && 'text-gray-400 line-through')
    }
  >
    <span className="overflow-ellipsis">{name}</span>
    {percentage === null || percentage.length === 0 || (
      <span className={'ml-auto ' + (!inStock && 'line-through')}>
        {percentage}%
      </span>
    )}
  </li>
)

const Info = ({ content }) => {
  return (
    <div className="p-3">
      <BlockContent blocks={content} />
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Header from './components/Header'
import Categories from './components/Categories'
import NotFound from './components/404'
import sanityClient from './client'

const App = () => {
  const [categories, setCategories] = useState(null)

  useEffect(
    () =>
      sanityClient
        .fetch(
          `*[_type == "category"] | order(order asc) {
        _id,
        name,
        alwaysActive,
        "info":  *[ _type == "info" && references(^._id) ]{
          name,
          content
        },
        "drinks": *[ _type == "drink" && references(^._id) ]  | order(name asc) {
          name,
          percentage,
          slug,
          inStock
        }
      }`,
        )
        .then(data => setCategories(data))
        .catch(console.error),
    [],
  )

  return (
    <Router>
      <div className="bg-primary text-white min-h-screen">
        <Header />
        <Switch>
          <Route exact path="/">
            <Categories categories={categories} />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
